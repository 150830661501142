"use client";

import { track } from "@vercel/analytics/react";
import React from "react";
import ShimmerButton from "@/components/magicui/shimmer-button";

export default function SignupButtonHero() {
  return (
    <ShimmerButton
      borderRadius="6px"
      shimmerSize="0.1em"
      shimmerDuration="5s"
      onClick={() => track("signup_button_clicked", { location: "hero" })}
      className="h-12"
    >
      Start tracking errors
    </ShimmerButton>
  );
}
