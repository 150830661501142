import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/screenshots/error-page.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/screenshots/error.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/screenshots/explain.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/screenshots/home.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/screenshots/integrations.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/screenshots/list.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/screenshots/recording.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/screenshots/widget.png");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/images/works-with.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/landing/next/signup-button-hero.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/(marketing)/landing/next/signup-button.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/magicui/number-ticker.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/carousel.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/dialog.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/separator.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/styled-link.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ui/tooltip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@bugpilot+plugin-nextjs@0.19.1_next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@1_ugpnocyfdhwjjsv7aqbrt45oyu/node_modules/@bugpilot/plugin-nextjs/dist/client/Bugpilot.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@bugpilot+plugin-nextjs@0.19.1_next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@1_ugpnocyfdhwjjsv7aqbrt45oyu/node_modules/@bugpilot/plugin-nextjs/dist/client/WidgetTrigger.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.1.0_react-dom@18.3.0-canary-b36ae8d7a-20231207_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/react-error-boundary@4.0.12_react@18.3.0-canary-b36ae8d7a-20231207/node_modules/react-error-boundary/dist/react-error-boundary.esm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/icon.png")